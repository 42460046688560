import { order } from '@/api'
export default {
    name: 'Payment',

    created() {
        // 判断是否有id
        if (!this.$route.query.sn) {
            this.$toast('缺少订单号')
            return setTimeout(() => { this.$router.push({ name: 'Default' }) }, 500);
        }

        // 获取数据
        this.handleGetData()
    },

    destroyed () {
        // 页面实例销毁时销毁 setTimeou 事件
        clearTimeout(this.isPayTime)
    },


    methods: {
        // 获取数据
        handleGetData() {
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            order.show({
                sn: this.$route.query.sn
            }).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)

                // 如果状态为 1 则跳转答题页面
                if (res.data.status == 1) return this.$router.push({name: 'Answer', query: {sn: res.data.sn}})

                // 数据赋值
                this.item = res.data.product
                this.payItems = res.data.paylist

                // 查询订单是否支付 ===== 遍历
                this.handleIsPay()
                
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },

        // 查询订单是否支付
        handleIsPay () {
            order.ispay({
                sn: this.$route.query.sn
            }).then(res => {
                if (res.status != 1) return this.$toast(res.message)
                if (res.data.status == 1) return this.$router.push({name: 'Answer', query: {sn: res.data.sn}})
                this.isPayTime = setTimeout(() => {
                    this.handleIsPay()
                }, 2000)
            }).catch(err => {
                console.log(err)
            })
        }
        
    },

    data() {
        return {
            isAjax: false,
            isQrcode: false, // 展示客服二维码
            qrcodeUrl: '',  // 客服二维码
            item: {},
            payItems: [],
            payId: 1, 
            isPayTime: null
        }
    }

}